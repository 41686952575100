/** @format */
import { Link } from "gatsby";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { AppContext } from "../../utils/ContextWrapper";
import React, { useEffect, useState, useContext } from "react";

function Productos() {
  const [productos, setProductos] = useState([]);

  const { store, actions } = useContext(AppContext);

  const { tipoProductoSeleccionado } = store;
  const { setTipoProductoSeleccionado } = actions;

  //const [recetas, setRecetas] = useState([]);

  const [tipoProducto, setTipoProducto] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickTipoProductoSeleccionado = (ev) => {
    setTipoProductoSeleccionado(tipoProducto[ev]);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    let urlEnvio = "";
    if (tipoProductoSeleccionado.idTipoProducto !== 0) urlEnvio = `/${tipoProductoSeleccionado.idTipoProducto}`;
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/productos${urlEnvio}`)
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setProductos(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/tiposProductos`)
      .then((result) => result.json())
      .then((result) => {
        setTipoProducto(result);
      })
      .catch((error) => {
        // setError(error);
      });
  }, [tipoProductoSeleccionado]);

  return (
    <>
      <div>
        <DropdownButton
          id="dropdown-basic-button"
          onSelect={handleClickTipoProductoSeleccionado}
          variant="warning"
          title={tipoProductoSeleccionado.descripcion}
        >
          {/*
          <Dropdown.Item eventKey="2">Pastas</Dropdown.Item>
          <Dropdown.Item eventKey="3">Galletas</Dropdown.Item>
          <Dropdown.Item eventKey="4">Probióticos y Vitaminasx</Dropdown.Item> */}
          {tipoProducto.map((e, key) => {
            return <Dropdown.Item eventKey={key}>{e.descripcion}</Dropdown.Item>;
            //return <option key={key} value={e.value}>{e.name}</option>;
          })}
        </DropdownButton>
      </div>
      <br></br>
      <div className="product-container">
        {loading && <div>Espera un momento, estamos obteniendo la informacion de los productos...</div>}
        {error && (
          <div>
            <br />
            <br />
            <br />
            <br />
            Ups!! Ocurrio un error al obtener los productos, intenta recargar la página nuevamente.
            <br /> Si el problema persiste contacte al administrador del sitio.
          </div>
        )}
        {productos.map((producto) => {
          return (
            <>
              <Link
                key={producto.idProducto}
                className="producto-componente"
                to={`/productos/detalle-producto/${producto.idProducto}`}
              >
                {producto.enOferta === 1 && <span className="oferta">EN OFERTA</span>}
                <img src={`${process.env.API_URL_CHAPIN}` + producto.url} alt={producto.nombreProducto} />
                <div>
                  {producto.nombreProducto}
                  <br />
                  {producto.enOferta === 1 && (
                    <>
                      <span style={{ color: "red" }}> Ahora Q.{producto.precio}</span>
                      <br />
                      <span className="texto-tachado">Q.{producto.precioQuetzales}</span>
                    </>
                  )}
                  {producto.enOferta === 0 && (
                    <>
                      <span>Q.{producto.precioQuetzales}</span>
                    </>
                  )}
                </div>
              </Link>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Productos;
